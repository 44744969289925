import React, { useEffect } from "react"
import { animateScroll } from "react-scroll"
import PropTypes from "prop-types"
import Header from "./header"
import FooterRed from "./footer-red"
import Footer from "./footer"
import LogoHero from "./logo-hero"
import Banner from "./banner"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import withLocation from "../../utils/withLocation"

const Layout = ({ children, className, search }) => {
  useEffect(() => {
    if (typeof Storage !== "undefined" && search.gclid) {
      sessionStorage.setItem("gclid", search.gclid)
    }
  }, [])

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey="6LdXZNgUAAAAAOnLiMciOy4alP8I9rlO2ZMYT3W0">
        <div className="root">
          <Banner />
          <Header />
          <div className="wrapper">
            <div
              className={className || "main inner-page"}
              data-device="desktop"
            >
              {children}
            </div>
          </div>
        </div>
        <LogoHero />
        <FooterRed />
        <footer className="mt8">
          <Footer />
        </footer>
        <div className="back-to-top-container" style={{ display: "block" }}>
          <a className="float-right back-to-top">
            <i
              className="icon-arrow-up"
              onClick={() => animateScroll.scrollToTop()}
            />
          </a>
        </div>
      </GoogleReCaptchaProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withLocation(Layout)
