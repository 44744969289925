import { Link, navigate } from "gatsby"
import React from "react"
import SearchBox from "./searchBox"

//import "../../styles/search.css"

import ContactHeader from "./contact-header"
import Navbar from "./navbar"

import logo from "../../images/content/logo.png"

const Header = () => (
  <header className="header">
    <div className="inner-header" id="page-top">
      <div className="row columns">
        <Link to="/" className="logo">
          <img alt="Supply Surplus LLC Logo" src={logo} />
        </Link>

        <div className="search-row">
          <SearchBox />
        </div>
      </div>
    </div>
    <button className="drawer-toggle reset">
      <span></span>
    </button>

    <div className="drawer">
      <Navbar />
      <ContactHeader />
    </div>

    <div className="content-overlay">
      <span className="icon-arrow-back"></span>
    </div>
  </header>
)

export default Header
