import React from "react"

const FooterRed = () => {
  return (
    <>
      <div className="bg-red center">
        <ul className="contact-banner">
          <li>
            <span>CONTACT A MEMBER OF OUR SALES TEAM TODAY</span>
          </li>
          <li>
            <a href="tel:4015260055">
              <i className="icon-phone-square"></i> 401-526-0055
            </a>
          </li>
          <li>
            <a href="mailto:sales@ssllc.com">
              <i className="icon-envelope"></i> sales@ssllc.com
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default FooterRed
