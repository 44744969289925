import React from "react"


import AMEA_ICON from "../../images/content/client-logos/amea.png"
import MDNA_ICON from "../../images/content/client-logos/mdna.png"
import MASSBIO_ICON from "../../images/content/client-logos/massbio.png"
import NAA_ICON from "../../images/content/client-logos/naa-auctioneer.png"
import NC_ICON from "../../images/content/client-logos/nc.png"
import LO_ICON from "../../images/content/client-logos/labops.png"

const LogoHero = () => (
  <div className="row columns mt6 mb6">
    <hr />
    <div className="row xsmall-up-2 small-up-3 medium-up-3 large-up-6 xlarge-up-6 xxlarge-up-6">
      <div className="column">
        <a href="https://www.amea.org/" target="_blank" rel="noopener noreferrer">
          <img
            src={AMEA_ICON}
            className="mb3 "
            alt="Association of Machinery and Equipment Appraisers"
          />
        </a>
      </div>
      <div className="column">
        <a href="https://labops.community/" target="_blank" rel="noopener noreferrer">
          <img
            src={LO_ICON}
            className="mb3 "
            alt="LabOps Unite"
          />
        </a>
      </div>
      <div className="column">
        <a href="https://www.massbio.org/" target="_blank" rel="noopener noreferrer">
          <img
            src={MASSBIO_ICON}
            className="mb3 "
            alt="Mass Bio"
          />
        </a>
      </div>
      <div className="column">
        <a href="https://www.mdna.org/" target="_blank" rel="noopener noreferrer">
          <img
            src={MDNA_ICON}
            className="mb3 "
            alt="Machinery Dealers National Association"
          />
        </a>
      </div>
      <div className="column">
        <a href="https://www.nclifesci.org/" target="_blank" rel="noopener noreferrer">
          <img
            src={NC_ICON}
            className="mb3 "
            alt="NC Life Sciences Organization"
          />
        </a>
      </div>
      <div className="column">
        <a href="https://www.auctioneers.org/" target="_blank" rel="noopener noreferrer">
          <img
            src={NAA_ICON}
            className="mb3 "
            alt="NAA Auctioneers"
          />
        </a>
      </div>
    </div>
  </div>
)

export default LogoHero
