import React, { useEffect, useRef } from "react"
import { globalHistory } from '@reach/router'
import { Link, graphql, useStaticQuery } from "gatsby"
import NavbarSubcat from "./navbar-subcat"

const liClassName = (expected, other) =>
  `${other || ''} ${
    (
      Array.isArray(expected)
        ? expected.findIndex(s => globalHistory.location.pathname.startsWith(s)) >= 0
        : globalHistory.location.pathname.startsWith(expected)
    )
      ? 'selected'
      : ''
  }`

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      allCategory(sort: { fields: [name], order: ASC }) {
        nodes {
          parentCategorySlug
          slug
          name
        }
      }
    }
  `)

  const menu = useRef(null)

  useEffect(() => {
    $(menu.current).dropdown_menu({
      site_class: "root",
      drawer_toggle_class: "drawer-toggle",
      drawer_overlay_class: "content-overlay",
      sub_indicator_class: "menu-arrow",
      hover_class: "drop-open",
      sub_indicators: true,
      touch_double_click: true,
      mobile_main_link_clickable: true,
      open_delay: 150,
      close_delay: 250,
    })
  }, [])

  // Get root level categories
  const rootCategoryNodes = data.allCategory.nodes.filter(category => {
    return category.parentCategorySlug == `/`
  })

  return (
    <>
      <nav className="menu" role="navigation menu" ref={menu}>
        <ul>
          <li className={liClassName("/buy-equipment", "has-megamenu")}>
            <Link to="/buy-equipment" className="bg-blue2">
              Buy Equipment
            </Link>

            <div className="mega-menu">
              <div className="row collapse left-align">
                <NavbarSubcat
                  header="Lab Equipment"
                  parentSlug="/lab-equipment"
                  allNodes={data.allCategory.nodes}
                />

                <NavbarSubcat
                  header="Packaging Equipment"
                  parentSlug="/packaging-equipment"
                  allNodes={data.allCategory.nodes}
                />

                <NavbarSubcat
                  header="Processing Equipment"
                  parentSlug="/processing-equipment"
                  allNodes={data.allCategory.nodes}
                />
              </div>
            </div>
          </li>

          <li className={liClassName("/sell-equipment")}>
            <Link to="/sell-equipment">Sell Equipment</Link>
          </li>

          <li className={liClassName("/auctions")}>
            <Link to="/auctions">Auctions</Link>
          </li>

          <li className={liClassName("/browse-manufacturers")}>
            <Link to="/browse-manufacturers">Manufacturers</Link>
          </li>

          <li className={liClassName("/catalog/featured")}>
            <Link to="/catalog/featured">Featured</Link>
          </li>


          <li className={liClassName("/catalog/new-arrivals", "drop-right")}>
            <Link to="/catalog/new-arrivals">New Arrivals</Link>
          </li>

          

          <li className={liClassName(["/about-us", "/our-services","/company-news" ,  "/contact-us"], "drop-right")}>
            <Link to="/about-us">About Us</Link>

            <ul>
              <li>
                <Link to="/our-services">Services</Link>
              </li>          
              <li>
                <Link to="/company-news">Company News</Link>
              </li> 
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Navbar
