import React from "react"

const ContactHeader = () => {
  return (
    <div className="corner">
      <div className="utility-links">
        <ul>
          <li>
            <a href="tel:4015260055">
              <i className="icon-phone-square"></i> 401-526-0055
            </a>
          </li>
          <li>
            <a href="mailto:sales@ssllc.com">
              <i className="icon-envelope"></i> sales@ssllc.com
            </a>
          </li>
        </ul>
      </div>

      <div className="social-links">
        <ul>
          <li>
            <a target="_blank" href="http://www.linkedin.com/company/208136">
              <i className="icon-linkedin"></i>
            </a>
          </li>

          <li>
            <a
              target="_blank"
              href="http://www.youtube.com/user/SurplusSolutionsLLC"
            >
              <i className="icon-youtube-play"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ContactHeader
