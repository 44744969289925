import { navigate } from "gatsby"
import React from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch } from "react-instantsearch-dom"
import Autocomplete from "./autocomplete"
import qs from "qs"


const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const alogliaIndex = process.env.GATSBY_ALGOLIA_INDEX_NAME || "Products"

const SearchBox = (props) => {
  const onSuggestionSelected = (_, { suggestion }) => {
    //console.log(suggestion)
    if (suggestion.type === "category") {
      navigate("/search/", { state: { category: suggestion.value } })
      _.target.blur()
    } else if (suggestion.type === "manufacturer") {
      navigate("/search/", { state: { manufacturerName: suggestion.value } })
      _.target.blur()
    } else {
      console.log(suggestion.slug)
      navigate("/" + suggestion.slug)
    }
  }

  const onSuggestionCleared = () => {}

  const onEnterPressed = (value) => {
    // Build search URL and send to search page
    let queryObj = { query: value }
    let query = `?${qs.stringify(queryObj)}`
    navigate(`/search/${query}`) 
  }

  return (
    <>
      <InstantSearch indexName={alogliaIndex} searchClient={searchClient}>
        
          <Autocomplete
            onSuggestionSelected={onSuggestionSelected}
            onSuggestionCleared={onSuggestionCleared}
            onEnterPressed={onEnterPressed}
            defaultRefinement={null}
          />
         
        
      </InstantSearch>
    </>
  )
}

export default SearchBox
