import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const NavbarSubcat = props => {
  const {parentSlug} = props;
  const {allNodes} = props;

  const filteredNodes = allNodes.filter(category => {
    return category.parentCategorySlug == `${parentSlug}`
  })

  var firstColumn = filteredNodes.slice(0,8);
  var secondColumn = filteredNodes.slice(9, 15);
  // if ( filteredNodes.length > 8 ) {
  //   firstColumn = filteredNodes.slice(null,8);
  //   if ( filteredNodes.length > )

  // }

  const firstColumnList = buildColumn(firstColumn);
  const secondColumnList = buildColumn(secondColumn);

  function buildColumn(nodes) {
    return (
      nodes.map( category => {   
        return (      
          <li key={category.slug}>
            <Link to={`/catalog${category.slug}`}>{category.name}</Link>
          </li>
        )
        })
    )
  }
  

  return (
    <div className="small-12 large-4 columns">
      {/* Header */}
      <h5 className="caps">
        <Link to={`/buy-equipment${props.parentSlug}`} className="menu-link">
          {props.header}
        </Link>
      </h5>
      {/* Items */}
      <div className="row collapse">
        {/* First column of items */}
        <div className="small-6 columns">
          <ul className="mega-menu-sub">      
            {firstColumnList}      
          </ul>
        </div>

        {/* Second column of items */}
        <div className="small-6 columns">
          <ul className="mega-menu-sub">
            {secondColumnList}

            <li className="view-all">
              <hr />
              <Link to={`/buy-equipment${parentSlug}`}>View All</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default NavbarSubcat
