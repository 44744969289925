import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getItem, setItem } from "../../services/session"

const DISABLE_BANNER_KEY = "disable-banner"

const Banner = () => {
  const [hasBanner, setHasBanner] = useState(false)
  const [disableBanner, setDisableBanner] = useState(
    getItem(DISABLE_BANNER_KEY)
  )

  useEffect(() => {
    if (hasBanner && !disableBanner) {
      $(".header").addClass("yesBanner")
      $(".wrapper").addClass("yesBanner")
    } else {
      $(".header").removeClass("yesBanner")
      $(".wrapper").removeClass("yesBanner")
    }
  }, [hasBanner, disableBanner])

  const data = useStaticQuery(graphql`
    query {
      allMarketing(
        filter: { active: { eq: "Active" }, typeName: { eq: "Banner" } }
      ) {
        nodes {
          buttonText
          buttonUrl
          name
        }
      }
    }
  `)

  if (disableBanner) {
    return null
  }

  const bannerNode =
    data.allMarketing.nodes.length > 0 ? data.allMarketing.nodes[0] : null
  if (!bannerNode) {
    //setHasBanner(false);
    return null
  } else {
    if (!hasBanner) setHasBanner(true)

    const onClose = () => {
      setDisableBanner(true)
      setItem(DISABLE_BANNER_KEY, true)
    }

    return (
      <div className="boxen-banner">
        <p>
          {bannerNode.name}
          {bannerNode.buttonUrl && (
            <a
              className="btn-more"
              href={`../../${bannerNode.buttonUrl
                .replace("https://www.ssllc.com/", "")
                .replace("https://ssllc.com/", "")}`}
            >
              {bannerNode.buttonText}
            </a>
          )}
        </p>
        <a onClick={onClose} className="btn-close-js">
          <span className="icon-close"></span>
        </a>
      </div>
    )
  }
}

export default Banner
